// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-julbord-tsx": () => import("./../../../src/pages/julbord.tsx" /* webpackChunkName: "component---src-pages-julbord-tsx" */),
  "component---src-pages-nyar-tsx": () => import("./../../../src/pages/nyar.tsx" /* webpackChunkName: "component---src-pages-nyar-tsx" */)
}

